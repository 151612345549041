import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTemplateModalComponent } from 'src/app/component/select-template-modal/select-template-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperModalComponent } from 'src/app/component/image-cropper-modal/image-cropper-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { ProductImagesCropperComponent } from 'src/app/component/product-images-cropper/product-images-cropper.component';

@Component({
  selector: 'app-generate-card',
  templateUrl: './generate-card.component.html',
  styleUrls: ['./generate-card.component.scss']
})
export class GenerateCardComponent implements OnInit {
  @ViewChild('stepper', {static : false}) private stepper: MatStepper;
  @ViewChild('change_photo', {static: false}) changePhotos: ElementRef;

  infoFormGroup: FormGroup;
  socialFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  templateId;
  templateInfo: any = {};
  color;
  htmlContent = "Thanks for checking out my virtual business card! I’m excited to introduce you to Blue Print Supply Chain. because I know you'll enjoy it as much as I have. Blue Print Supply Chain. helps me keep track of my prospects, my team, and my time so I can get more accomplished every day. Feel freeto contact me with any questions.";
  loading = false;
  user: any;
  invite_code:any;
  PackageInfo: any;
  videoLimit = 1;
  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private global: GlobalService,
    ) {
      this.infoFormGroup = this.fb.group({
        templateId: '',
        cardTitle: ['', Validators.required],
        firstName: 'John',
        lastName: 'Doe',
        email: 'team@blueprint.com',
        phoneNumber: '99122301',
        alternativePhoneNo: '',
        companyName: 'Blue Print Supply Chain.',
        jobTitle: 'Affiliate',
        address: 'Blue Print Supply Chain. team',
        aboutText: "",
        phoneTextAllow: true,
        showSaveButton: true,
        showForwardButton: true,
        showInviteCode: true,
        inviteCode: ''
        // inviteCode: localStorage.getItem('invite_Code')
      });
      this.infoFormGroup.patchValue({aboutText: this.htmlContent})
      this.socialFormGroup = this.fb.group({
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        youtube: 'https://www.youtube.com/',
        instagram: 'https://www.instagram.com/',
        linkedin: 'https://www.linkedin.com/',
        pinterest: 'https://www.pinterest.com/',
        skypeID: '',
        whatsappID: '',
        snapchat: '',
        lineID: '',
        voxerID: '',
        youtubeVideos: this.fb.array([
          this.fb.group({
            youtubeTitle: '',
            youtubeLink: 'https://www.youtube.com/embed/rW3Pq5egNis'
          })
        ]),
        vimeoVideos: this.fb.array([
          this.fb.group({
            vimeoTitle: '',
            vimeoLink: ''
          })
        ]),
        linkButtons: this.fb.array([
          this.fb.group({
            linkButtonTitle: 'Learn More',
            websiteLink: 'https://card.blueprintsc.store/'
          })
        ])
      });
  
      this.fourFormGroup = this.fb.group({
        secondCtrl: ['', Validators.required]
      });
  
  
      this.templateInfo.infoFormData = this.infoFormGroup.value;
      this.templateInfo.socialFormData = this.socialFormGroup.value;
      this.templateInfo.buttonColor = '#004080';
    }

  ngOnInit() {
    
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {

        // console.log(res);
        // console.log(res.Users.refer_code);
        this.invite_code = res.Users.refer_code;
        this.PackageInfo = res.Users.PackageData;
        this.videoLimit = (this.PackageInfo.videos === '1 video link') ? 1 : 
                           (this.PackageInfo.videos === '2 video link') ? 2 :
                           (this.PackageInfo.videos === '3 video link') ? 3 : 10; 
                          
        //  console.log('video limit =>', this.videoLimit);
        // localStorage.setItem('invite_Code',res.Users.refer_code);
        this.templateInfo.infoFormData.inviteCode = this.invite_code;

        },(err => {
          console.log(err)
          
      })
    )
   

    this.openSelectTemplateModal();

  }

  get youtubeVideosArr() { return <FormArray>this.socialFormGroup.get('youtubeVideos'); }
  get vimeoVideosArr() { return <FormArray>this.socialFormGroup.get('vimeoVideos'); }
  get linkButtonsArr() { return <FormArray>this.socialFormGroup.get('linkButtons'); }

  addYoutubeVideos(){
    let control = <FormArray>this.socialFormGroup.controls.youtubeVideos;
    control.push(
      this.fb.group({
        youtubeTitle: "",
        youtubeLink: ""
      })
    );
  }

  addVimeoVideos(){
    let control = <FormArray>this.socialFormGroup.controls.vimeoVideos;
    control.push(
      this.fb.group({
        vimeoTitle: "",
        vimeoLink: ""
      })
    );
  }

  addButtons(){
    let control = <FormArray>this.socialFormGroup.controls.linkButtons;
    control.push(
      this.fb.group({
        linkButtonTitle: "",
        websiteLink: ""
      })
    );
  }

  removeItem(control, i){
    control.removeAt(i);
    this.templateInfo.socialFormData = this.socialFormGroup.value;
  }

  templateSelcted(){
    if(this.templateId != null){
      this.stepper.next();
    }else{
      this.snackBar.open('Please, select template.', 'X', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition:'right',
        panelClass: ['error_snackBar']
      });
    }
  }

  checkInfoValidation(){
    if(this.infoFormGroup.invalid){
      this.snackBar.open('Please, Fill the mandatory field.', 'X', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition:'right',
        panelClass: ['error_snackBar']
      });
    }else{
      this.stepper.next();
    }
  }

  valueChange(){
    // console.log(this.socialFormGroup.value.youtubeVideos);
    let length = this.socialFormGroup.value.youtubeVideos.length;
    for(let i = 0 ; i < length ; i++)
    {
      let link = this.socialFormGroup.value.youtubeVideos[i].youtubeLink;
      // console.log(link);
      let str : any = [] = link.split('v=');
      console.log(str.length)
      if(str.length == 1)
      {
        console.log(str[0]);
        let you_link = str[0];
        this.socialFormGroup.value.youtubeVideos[i].youtubeLink = you_link;
      }
      else
      {
        console.log(str[0], str[1]);
        let you_link = 'https://www.youtube.com/embed/'+str[1];
        this.socialFormGroup.value.youtubeVideos[i].youtubeLink = you_link;
      }
      console.log(str);
    }
    this.infoFormGroup.patchValue({aboutText: this.htmlContent})
    this.templateInfo.infoFormData = this.infoFormGroup.value;
    this.templateInfo.socialFormData = this.socialFormGroup.value;
    this.templateInfo.infoFormData.inviteCode = this.invite_code;
    // console.log(this.htmlContent);
    // console.log(this.templateInfo);
  }

  changeButtonColor(color){
    this.templateInfo.buttonColor = color;
    // console.log(this.templateInfo.buttonColor)
  }

  // modals
  openSelectTemplateModal(){
    let dialogRef = this.dialog.open(SelectTemplateModalComponent);
    
    dialogRef.afterClosed().subscribe(
      data => {
        this.templateId = data;
        this.infoFormGroup.patchValue({templateId : this.templateId});
      }
    )
  }

  openImageProductCropperModal(){
    // console.log('product')
    let dialogRef = this.dialog.open(ProductImagesCropperComponent,{
      width: '100vh',
      data: this.PackageInfo.product_image
    });

    dialogRef.afterClosed().subscribe(
      image => {
        // console.log(str);
        // console.log(image);
        if(image != '' || image != ' ' || image != undefined){
          // console.log('image');
          this.templateInfo.changeProductImages = image;
        }
        // if(str === 'changePhoto'){
        //   this.templateInfo.change_photo = image;
        //   // console.log('changePhoto');
        // }else if(str === 'changeLogo'){
        //   this.templateInfo.change_logo = image;
        //   // console.log('changeLogo');
        // }else{
        //   this.templateInfo.changeProductImages = image;
        //   // console.log('else');
        }
        // console.log(this.templateInfo)
      )
  }

  openImageCropperModal(str){
    let dialogRef = this.dialog.open(ImageCropperModalComponent,{
      width: '100vh',
    });

    dialogRef.afterClosed().subscribe(
      image => {
        // console.log(str);
        // console.log(image);
        if(str === 'changePhoto'){
          this.templateInfo.change_photo = image;
          // console.log('changePhoto');
        }else if(str === 'changeLogo'){
          this.templateInfo.change_logo = image;
          // console.log('changeLogo');
        }
        // console.log(this.templateInfo)
      }
    )
  }

  creatCard(){
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    console.log(this.infoFormGroup.value);
    let data = this.templateInfo;
    data.user_id = localStorage.getItem('user_id');
    data.infoFormData =JSON.stringify(this.infoFormGroup.value);
    data.socialFormData =JSON.stringify(this.socialFormGroup.value);
    data.changeProductImages =JSON.stringify(this.templateInfo.changeProductImages);
    // console.log(this.templateInfo.changeProductImages)
    // console.log(data)
    data.colorTheme =this.templateInfo.buttonColor;
    this.http.post(this.global.apiUrl+'/saveCard', data, {headers: header}).subscribe(
      (res : any) => {
        this.loading = false;
        // console.log(res)
        this.router.navigateByUrl('/cards');
        if(res.message == "You exceeds your cards limit"){
          this.snackBar.open(res.message, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
        }else{
          this.snackBar.open('Card created successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
        }
          this.templateInfo = [];
        },(err => {
          this.loading = false;
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }


  getUserData(){
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/userData', {user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        console.log(res);
        console.log(res.Users.refer_code);
        this.invite_code = res.Users.refer_code;
        this.user = res;
        },(err => {
          console.log(err)
          
      })
    )
  }

}
