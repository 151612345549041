import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
declare var paypal;
// declare var Stripe: any;

@Component({
  selector: 'app-select-package-modal',
  templateUrl: './select-package-modal.component.html',
  styleUrls: ['./select-package-modal.component.scss']
})
export class SelectPackageModalComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paymentElement: ElementRef;

  selectedPackage: any = null;
  allPackages: any = [];
  loading = false;
  package_id: any;
  product = {
    price: 77.7,
    description: 'sdsdsds'
  }
  paidFor = false;

  handler: any = null;
  price:number = null;
  stripeCheck:boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private http: HttpClient,
    private global: GlobalService,
    private dialogRef: MatDialogRef<SelectPackageModalComponent>) { }

  ngOnInit() {
    this.getPackages();
    this.loadStripe();
  }
  loadStripe() {

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          // key: 'pk_live_51HEGkGKlXwFRf1XecIs1EcLVpQ1JepzmZhlj1YzSRC7MrXTbdSJsP6ui5UnEfWQvAdFAWBdommZIL4spVJADmnqI00C4Tjiryq',
          key: 'pk_test_51HEGkGKlXwFRf1XeEpKDjcCnaoDSGhIsEcPPB6ndH2SYMDBXd7S5I2CsEq0MV2fFXhhVifuL2EUzaumVjh65gla800PrVG0B0a',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            // console.log(token)
            // alert('Payment Success!!');
          }
        });
      }

      window.document.body.appendChild(s);
    }
  }

  getPackages() {
    this.loading = true;
    // console.log('get cards')
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });

    let data = {
      user_id: localStorage.getItem('user_id')
    }

    this.http.post(this.global.apiUrl + '/getPackages', data, { headers: header }).subscribe(
      (res: any) => {
        console.log(res);
        this.allPackages = res.Package;
        console.log('packages =>',this.allPackages);
        this.loading = false;
      }, (err => {
        // console.log(err)
        this.loading = false;
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('user_id');
        this.router.navigateByUrl('/');
        this.snackBar.open('Sorry! something went wrong.', 'X', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['error_snackBar']
        });
      })
    )
  }

  selectPackage(pkg) {
    console.log('package =>',pkg);
    this.selectedPackage = pkg;
    localStorage.setItem('pg_id',this.selectedPackage.id);
    this.price  = this.selectedPackage.net_price ? this.selectedPackage.net_price : this.selectedPackage.price;
    console.log(this.selectedPackage);
    console.log('price =>',this.price);
  }
  pay() {

    // this.stripeCheck = val;
    // console.log(this.stripeCheck);
    if (Number(this.selectedPackage.price) != 0) {
      

      var handler = (<any>window).StripeCheckout.configure({
        // key: 'pk_live_51HEGkGKlXwFRf1XecIs1EcLVpQ1JepzmZhlj1YzSRC7MrXTbdSJsP6ui5UnEfWQvAdFAWBdommZIL4spVJADmnqI00C4Tjiryq',
        key: 'pk_test_51HEGkGKlXwFRf1XeEpKDjcCnaoDSGhIsEcPPB6ndH2SYMDBXd7S5I2CsEq0MV2fFXhhVifuL2EUzaumVjh65gla800PrVG0B0a',
        locale: 'auto',
        token: (token: any) => {
          // You can access the token ID with `token.id`.
          // Get the token ID to your server-side code for use.
          // console.log('stripe token =>', token);
          // console.log(token.id);
          this.stripwApi(this.selectedPackage.net_price ? this.selectedPackage.net_price : this.selectedPackage.price,token.id,this.selectedPackage.description);

          // this.dialogRef.close(this.selectedPackage.id);
          // alert('Token Created!!');
        }
      });

      handler.open({
        name: 'Blue Print Supply Chain.',
        description: 'Make Cool Cards',
        amount: Number(this.selectedPackage.net_price ? this.selectedPackage.net_price : this.selectedPackage.price) * 100
      });
      
    }
    else {
      this.closeModal(this.selectedPackage.id)
    }

  }
  paymentFun() {
    if (Number(this.selectedPackage.price) != 0) {
      this.paidFor = true;
      // console.log('select');
      // console.log(this.selectedPackage)
      setTimeout(() => {
        paypal.Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.selectedPackage.description,
                  amount: {
                    currency_code: 'USD',
                    value: Number(this.selectedPackage.net_price ? this.selectedPackage.net_price : this.selectedPackage.price)
                  }
                }
              ]
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paidFor = true;
            console.log('paypal =>', data);
            // console.log(order)
            if (order.status == "COMPLETED") {
              // console.log('complete');
              this.closeModal(this.selectedPackage.id, null, 'Paypal');
            }
          },
          onError: err => {
            console.log(err);
            this.snackBar.open('Sorry! something went wrong.Please try again.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['error_snackBar']
            });
            this.dialogRef.close();
          }
        }).render(this.paymentElement.nativeElement);
      }, 2000);
    } else {
      this.closeModal(this.selectedPackage.id, null, 'Paypal');
    }
  }

  continue(){
    this.closeModal(this.selectedPackage.id, null, null); 
  }

  closeModal(package_id,balance_transaction?, type?) {
    console.log(package_id)
    const data = {
      package_id: package_id,
      payment_id: balance_transaction,
      method: type
    }
    this.dialogRef.close(data);
    // this.dialogRef.close(package_id);
  }

  stripwApi(price,token,description){
    this.loading = true;
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('admin_access_token')
    });

     let data = {
      amount:price,
      stripeToken:token,
      description:description
     };

    console.log(data);
    this.http.post(this.global.apiUrl+'/stripe',data, {headers: header}).subscribe(
      (res : any) => {
          if(res.status == "Success"){
            // console.log(res.message);
            this.snackBar.open('Payment Pay Successfully.', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right'
            });
            const data = {
              package_id: this.selectedPackage.id,
              payment_id: res.balance_transaction,
              method: 'stripe'
            }
            this.dialogRef.close(data);
          }
          else{
            this.snackBar.open('Sorry! No Payment .', 'X', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right',
              panelClass: ['error_snackBar']
            });
          }
          // this.dataSource = res.listOfReferals;
          this.loading = false;
         
        },(err => {
          console.log(err)
          this.loading = false;
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }
}
