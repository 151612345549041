import { Component, Inject, Optional, OnInit } from '@angular/core'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgNavigatorShareService } from 'ng-navigator-share';

@Component({
  selector: 'app-share-card-link-modal',
  templateUrl: './share-card-link-modal.component.html',
  styleUrls: ['./share-card-link-modal.component.scss'],
  providers:[NgNavigatorShareService]
})
export class ShareCardLinkModalComponent implements OnInit {

  private ngNavigatorShareService: NgNavigatorShareService;

  constructor(
    public dialogRef: MatDialogRef<ShareCardLinkModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ngNavigatorShareService: NgNavigatorShareService
  ) {

    this.ngNavigatorShareService = ngNavigatorShareService;
    
  }

  ngOnInit() {
    console.log(this.data);
    // this.share();


  }

  closeModal(){
    this.dialogRef.close();
  }

  // share(){
  //   console.log('share');

    // if (navigator.share) {
    //   navigator.share({
    //     title: 'Umyocard',
    //     text: 'card link ',
    //     url: this.data.url,
    //   })
    //     .then(() => {
    //       console.log('Successful share');

    //     })
    //     .catch((error) => console.log('Error sharing', error));
    // }
  // }

  share() {
    
    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.ngNavigatorShareService.share({
      title: 'Blue Print Supply Chain.',
      text: 'share card link',
      url: this.data.url
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    });
  }
}
