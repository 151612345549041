import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // apiUrl = "http://localhost:4002/api";
  // apiUrl = "http://cards.akhzir.com/api";
  // apiUrl = "https://umyocards.com/api_umyocards/public/api";
  apiUrl = "https://card.blueprintsc.store/api_umyocards/public/api";
  sentMessageUserObj : any = [];

  constructor() { }


  private extra: any;
  public setExtras(data) {
      this.extra = data;
  }
  get getExtras() {
      return this.extra;
  }
  
}
