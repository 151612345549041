import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { MatDialog } from '@angular/material/dialog';
import { ShareCardLinkModalComponent } from 'src/app/component/share-card-link-modal/share-card-link-modal.component';
import { SelectPackageModalComponent } from 'src/app/component/select-package-modal/select-package-modal.component';
import {QRCodeComponent} from 'angularx-qrcode';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit, AfterViewInit  {
  @ViewChild('parent', {static: false}) parent: ElementRef<any>;
  @ViewChild(QRCodeComponent, {static: false}) qrcodechild: QRCodeComponent;
  allCards: any = [];
  loading= false;
  /////////////////////////
  qrdata = 'https://card.blueprintsc.store/';
  qrcodeDisplay = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private http: HttpClient,
    private global: GlobalService) { }

  ngOnInit() {
    this.getCards();
  }
  ngAfterViewInit (){
    console.log("afterinit");

   }

  createCard(e){
    // console.log(e);
  }

  getCards(){
    this.loading = true;
    // console.log('get cards')
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = {
      user_id : localStorage.getItem('user_id')
    }

    this.http.post(this.global.apiUrl+'/getCard', data, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.allCards = res.Card;
        // console.log(this.allCards)
        this.loading = false;
        },(err => {
          // console.log(err)
          this.loading = false;
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('user_id');
          this.router.navigateByUrl('/');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  deleteCard(id){
    // console.log('delete cards')
    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });

    let data = {
      user_id : localStorage.getItem('user_id'),
      card_id : id
    }
    this.http.post(this.global.apiUrl+'/deleteCard', data, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        this.allCards = this.allCards.filter(element => element.id !== id);
        // console.log(this.allCards)
        },(err => {
          // console.log(err)
          // localStorage.removeItem('access_token')
          // localStorage.removeItem('user_id');
          // this.router.navigateByUrl('/');
          // this.snackBar.open('Sorry! something went wrong.', 'X', {
          //   duration: 3000,
          //   verticalPosition: 'top',
          //   horizontalPosition:'right',
          //   panelClass: ['error_snackBar']
          // });
      })
    )
  }

  editCard(id){
    this.router.navigateByUrl('/edit-card/'+id);
  }

  previewCard(id){
    let url = this.router.url+'/preview-card/'+id;
    window.open(url);
    // console.log(url)
  }
  
  openShareLinkModal(id){
    let url = 'https://card.blueprintsc.store/cards/share-card/'+id;
    this.dialog.open(ShareCardLinkModalComponent, {
      width: '400px',
      data: {url : url}
    });
  }


  updatePackage(id){

    let header : HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ localStorage.getItem('access_token')
    });
    
    // console.log(localStorage.getItem('user_id'));
    this.http.post(this.global.apiUrl+'/updateUserPackage', {package_id: id, user_id: localStorage.getItem('user_id')}, {headers: header}).subscribe(
      (res : any) => {
        // console.log(res);
        // this.userList = res.Users;
        // this.router.navigateByUrl('/cards');
          this.snackBar.open('Package upgrade successfully.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
          });
      
        },(err => {
          // console.log(err)
          this.router.navigateByUrl('/cards');
          this.snackBar.open('Sorry! something went wrong.', 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition:'right',
            panelClass: ['error_snackBar']
          });
      })
    )
  }

  openPackageModal(){
      let dialogRef = this.dialog.open(SelectPackageModalComponent, {
        width: '650px',
      });
  
      dialogRef.afterClosed().subscribe(id => {
        // console.log(id);
        if(id != null || id != undefined){
          this.updatePackage(id)
        }
      });
    }
    selectCard(id){
    // console.log(id)
    this.qrdata = 'https://card.blueprintsc.store/cards/share-card/'+id;
    }
    saveAsImage(id) {
      // console.log(id)
      
      console.log('next =>', this.parent);
      const html = this.qrcodechild.qrcElement.nativeElement.innerHTML;
      let img64: string = html.substr(0, html.length - 2).split('base64,')[1];
      const dispimg64 = "data:image/png;base64," + img64;
      console.log('dispimg64 =>',dispimg64);
      // fetches base 64 date from image
      // const parentElement = parent.el.nativeElement.querySelector("img").src;
  
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(dispimg64);
  
      // saves as image
      /* if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
      } else {  */// chrome
        const blob = new Blob([blobData], { type: "image/png" });
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Qrcode';
        link.click();
      // }
  
    }
  
    private convertBase64ToBlob(Base64Image: any) {
      // SPLIT INTO TWO PARTS
      const parts = Base64Image.split(';base64,');
      // HOLD THE CONTENT TYPE
      const imageType = parts[0].split(':')[1];
      // DECODE BASE64 STRING
      const decodedData = window.atob(parts[1]);
      // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
      const uInt8Array = new Uint8Array(decodedData.length);
      // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }
      // RETURN BLOB IMAGE AFTER CONVERSION
      return new Blob([uInt8Array], { type: imageType });
    }
}
